body {
    overflow-x: hidden;
}

.upper {
    width: 100vw;
    height: 50vh;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.logo {
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
}
.logo img {
    height: 100%;
}
.logo h3 {
    font-size: 1.1vw;
    font-family: 'Roboto';
    font-weight: 300;
}

.headerBox {
    width: 65%;
    height: 60%;
    background-color: white;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.info {
    display: flex;
    height: 17%;
    text-align: start;
    align-self: flex-start;
    align-items: center;
    justify-content: flex-start;
    margin-left: 2%;
}
.nameBox {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: rgb(255,255,200);
    color: black;
    font-size: .7vw;
    font-family: 'Roboto';
    font-weight: 600;
}
.info p {
    text-align: center;
    font-size: .7vw;
    font-family: 'Roboto';
    font-weight: 300;
    margin-left: 10px;
}

.headerBox h1 {
    width: 90%;
    height: 65%;
    text-align: center;
    font-size: 2vw;
    font-family: 'Roboto';
    font-weight: 600;
    text-align: center;
    line-height: 5vh;
    align-items: flex-start;
    display: flex;
}

.lower {
    width: 100vw;
    height: 50vh;
}

.lowerContent {
    width: 100%;
    height: 67%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 3%;
}
.signUpTextBox {
    text-align: center;
    font-family: 'Roboto';
    height: 17%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}
.signUpTextBox h3 {
    font-weight: 300;
    font-size: 1.3vw;
}
.signUpTextBox p {
    font-weight: 700;
    font-size: .81vw;
}

.signUpBtnBox {
    display: flex;
    width: 30%;
    justify-content: center;
}
.signUpBtn {
    background-color: white;
    color: black;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 1.1vw;
    box-shadow: 5px 5px 10px rgb(177, 177, 177);
    padding: 3.5% 6%;
    border-radius: 7px;
    cursor: pointer;
}
.signUpBtn:hover {
    background-color: rgb(255,255,200);
    box-shadow: 6px 6px 10px rgb(167, 167, 167);
    transition: all .17s ease-in-out;
}

.shareBtn {
    cursor: pointer;
}

.popupContainer {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .2s ease;
    visibility: hidden;
}
.popupContainer.active {
    opacity: 1;
    visibility: visible;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 40%;
    height: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popupHeader {
    width: 90%;
    height: 15%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.error {
    color: red;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: .9vw;
    margin-left: 5%;
    transition: all 1s ease;
}
.closeBtn {
    cursor: pointer;
}
.closeBtn:hover {
    color: gray;
}

.inputSection {
    width: 80%;
    height: 45%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 1%;
}
.inputSection h2 {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 1.1vw;
    margin-top: 7%;
}
.inputSection input {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 1.8vw;
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: gray;
    border-radius: 0;
    outline: none;
    margin-top: 2px;
    padding: 1.5%;
    width: 80%;
}
.inputSection input:focus {
    border-color: black;
}

.footer {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footerBtn {
    background-color: white;
    color: black;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 1.1vw;
    box-shadow: 5px 5px 10px rgb(177, 177, 177);
    padding: 2% 5%;
    border-radius: 7px;
    cursor: pointer;
}
.footerBtn.disabled {
    background-color: white;
    color: gray;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 1.1vw;
    box-shadow: 5px 5px 10px rgb(177, 177, 177);
    padding: 2% 5%;
    border-radius: 7px;
    cursor: no-drop;
}
.footerBtn.disabled:hover {
    background-color: white;
    box-shadow: 0px 0px 0px rgb(0, 0, 0);
    color: gray;
}
.footerBtn:hover {
    background-color: rgb(255,255,200);
    box-shadow: 6px 6px 10px rgb(167, 167, 167);
    transition: all .17s ease-in-out;
}
.g-recaptcha {
    transform: scale(0.75);
}
.footer img {
    height: 33%;
}

.success-animation { 
    justify-content: center;
    align-items: center;
    height: 85%;
    width: 100%;
    display: flex;
}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    /* top: 5px;
    right: 5px; */
    margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}